import { useState } from "react";
import Expenses from "./components/Expenses/Expenses";
import NewExpense from "./components/NewExpense/NewExpense";

const DUMMY_EXPENSES = [
  {
    id: "e1",
    title: "Toilet Paper",
    amount: 94.12,
    date: new Date(2020, 7, 14)
  },
  { 
    id: "e2", 
    title: "New TV", 
    amount: 799.49, 
    date: new Date(2021, 2, 12)
  },
  {
    id: "e3",
    title: "Car Insurance",
    amount: 294.67,
    date: new Date(2021, 8, 28)
  },
  {
    id: "e4",
    title: "New Desk (Wooden)",
    amount: 450,
    date: new Date(2021, 5, 12)
  },
  {
    id: "e5",
    title: "New Book (Thriller)",
    amount: 44.99,
    date: new Date(2023, 0, 7)
  },
  {
    id: "e6",
    title: "Armchair",
    amount: 250,
    date: new Date(2023, 9, 10)
  },
  {
    id: "e7",
    title: "Spotify Subscription",
    amount: 15.90,
    date: new Date(2020, 11, 31)
  },
  {
    id: "e8",
    title: "Sport Shoes",
    amount: 40.50,
    date: new Date(2022, 6, 18)
  },
  {
    id: "e9",
    title: "Board Game",
    amount: 50,
    date: new Date(2022, 9, 25)
  },
  {
    id: "e10",
    title: "New Hat",
    amount: 27.85,
    date: new Date(2023, 10, 4)
  },
];

function App() {
  const [expenses, setExpenses] = useState(DUMMY_EXPENSES);

  function addExpenseHandler(expense) {
    setExpenses((prevExpenses) => {
      return [expense, ...prevExpenses];
    });
  }

  return (
    <div>
      <NewExpense onAddExpense={addExpenseHandler} />
      <Expenses expenses={expenses} />
    </div>
  );
}

export default App;
